import rl from 'assets/images/cards/games/rl.png';

// Icons
import rl_icon from 'assets/images/games/rl/rl_icon.png';
import icon_white from 'assets/images/games/rl/rl_landing_icon.png';

// Rank images
import rl_unranked from 'assets/images/games/rl/rl_unranked.png';
import rl_bronze1 from 'assets/images/games/rl/rl_bronze1.png';
import rl_bronze2 from 'assets/images/games/rl/rl_bronze2.png';
import rl_bronze3 from 'assets/images/games/rl/rl_bronze3.png';
import rl_silver1 from 'assets/images/games/rl/rl_silver1.png';
import rl_silver2 from 'assets/images/games/rl/rl_silver2.png';
import rl_silver3 from 'assets/images/games/rl/rl_silver3.png';
import rl_gold1 from 'assets/images/games/rl/rl_gold1.png';
import rl_gold2 from 'assets/images/games/rl/rl_gold2.png';
import rl_gold3 from 'assets/images/games/rl/rl_gold3.png';
import rl_platinum1 from 'assets/images/games/rl/rl_platinum1.png';
import rl_platinum2 from 'assets/images/games/rl/rl_platinum2.png';
import rl_platinum3 from 'assets/images/games/rl/rl_platinum3.png';
import rl_diamond1 from 'assets/images/games/rl/rl_diamond1.png';
import rl_diamond2 from 'assets/images/games/rl/rl_diamond2.png';
import rl_diamond3 from 'assets/images/games/rl/rl_diamond3.png';
import rl_champion1 from 'assets/images/games/rl/rl_champion1.png';
import rl_champion2 from 'assets/images/games/rl/rl_champion2.png';
import rl_champion3 from 'assets/images/games/rl/rl_champion3.png';
import rl_gm1 from 'assets/images/games/rl/rl_gm1.png';
import rl_gm2 from 'assets/images/games/rl/rl_gm2.png';
import rl_gm3 from 'assets/images/games/rl/rl_gm3.png';
import rl_ssl from 'assets/images/games/rl/rl_ssl.png';

import { ranks_3v3, ranks_2v2, ranks_1v1 } from './ranks';

export default {
  name: 'Rocket League',
  tag: 'RL',
  img: rl,
  icon: rl_icon,
  iconWhite: icon_white,
  platforms: ['pc', 'xbox', 'ps', 'switch'],
  modes: [
    {
      name: '3v3',
      amountPlayers: 3,
      ranks: ranks_3v3,
    },
    {
      name: '2v2',
      amountPlayers: 2,
      ranks: ranks_2v2,
    },
    {
      name: '1v1',
      amountPlayers: 1,
      ranks: ranks_1v1,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament'],
    },
    {
      category: 'mode',
      tags: ['3v3', '2v2', '1v1'],
    },
    {
      category: 'tournament_leagues',
      tags: [
        'RLCS',
        'Nitro League (DEU)',
        'Telialigaen (NOR)',
        'Svenska Raketligan (SWE)',
        'TCS Esports League (AUT)',
        'RaketLigaen (DNK)',
        'Swiss Esports League (CHZ)',
        'Zebra League (PRT)',
        'Rakettiliiga (FIN)',
        'Elite Series (BEL/NLD/LUX)',
        'RSC EU (Europe)',
        'ECC (Europe)',
      ],
    },
  ],

  mmr: [
    'Unranked',
    'Bronze I',
    'Bronze II',
    'Bronze III',
    'Silver I',
    'Silver II',
    'Silver III',
    'Gold I',
    'Gold II',
    'Gold III',
    'Platinum I',
    'Platinum II',
    'Platinum III',
    'Diamond I',
    'Diamond II',
    'Diamond III',
    'Champion I',
    'Champion II',
    'Champion III',
    'Grand Champion (1400)',
    'Grand Champion (1500)',
    'Grand Champion (1600)',
    'Grand Champion (1700)',
    'Supersonic Legend (1800)',
    'Supersonic Legend (1900)',
    'Supersonic Legend (2000)',
    'Supersonic Legend (2100)',
    'Supersonic Legend (2200+)',
  ],
  mmrShort: [
    'UNRKD',
    'B I',
    'B II',
    'B III',
    'S I',
    'S II',
    'S III',
    'G I',
    'G II',
    'G III',
    'P I',
    'P II',
    'P III',
    'DIA I',
    'DIA III',
    'DIA III',
    'C I',
    'C II',
    'C III',
    '1400',
    '1500',
    '1600',
    '1700',
    '1800',
    '1900',
    '2000',
    '2100',
    '2200+',
  ],
  mmrImages: [
    rl_unranked,
    rl_bronze1,
    rl_bronze2,
    rl_bronze3,
    rl_silver1,
    rl_silver2,
    rl_silver3,
    rl_gold1,
    rl_gold2,
    rl_gold3,
    rl_platinum1,
    rl_platinum2,
    rl_platinum3,
    rl_diamond1,
    rl_diamond2,
    rl_diamond3,
    rl_champion1,
    rl_champion2,
    rl_champion3,
    rl_gm1,
    rl_gm2,
    rl_gm3,
    rl_gm3,
    rl_ssl,
    rl_ssl,
    rl_ssl,
    rl_ssl,
    rl_ssl,
  ],
};
