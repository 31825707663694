import nl from 'assets/images/cards/games/nl.png';

export default {
  // Nitro league has no tags as it is no game you can select for the playerboard
  name: 'Rocket League (Nitro League)',
  tag: 'NL',
  img: nl,
  platforms: ['pc', 'xbox', 'ps', 'switch'],
  modes: [],
  tagsPerCategory: [],
  mmr: [
    'Starter Division',
    'Division 8',
    'Division 7',
    'Division 6',
    'Division 5',
    'Division 4',
    'Division 3',
    'Division 2',
    'Division 1',
  ],
  mmrImages: [

  ],
};
